













































































import Vue from 'vue';
import moment from 'moment';
import * as api from '@/api/tenantManage';
export default Vue.extend({
  data() {
    return {
      dataVisible: false,
      listLoading: false,
      certificationType: 'enterprise',
      listData: [],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        total: 0,
        showQuickJumper: true,
        showTotal: (total: number) => {
          return `共 ${total} 条`;
        },
        onChange: (page, pageSize) => {
          this['pagination'].current = page;
          this['pagination'].pageSize = pageSize;
          this['getTenantList']();
        },
        onShowSizeChange: (current, size) => {
          this['pagination'].current = current;
          this['pagination'].pageSize = size;
          this['getTenantList']();
        },
      },
    }
  },
  methods: {
    init() {
      this.dataVisible = true;
    },
    handleCancel() {
      this.dataVisible = false;
    },
    titleClick(id: string) {
      // 跳转的企业认证审核详情页
      if (this.certificationType === 'enterprise') {
        this.$router.push({
          path: '/platform/tenantManage/details',
          query: { id: id },
        });
      }
      // 跳转的个人认证审核详情页
      if (this.certificationType === 'personal') {
        this.$router.push({
          path: '/platform/tenantManage/personalDetails',
          query: { id: id },
        });
      }
    },
    getTenantList() {
      if (this.certificationType === 'enterprise') {
        this.getEnterpriseTenantList();
      }
      if (this.certificationType === 'personal') {
        this.getPersonalTenantList();
      }
    },
    getEnterpriseTenantList() {
      this.listLoading = true;
      api
        .getEnterpriseTenantList(
          this.pagination.current,
          this.pagination.pageSize,
          this.searchForm.tenantName,
          this.searchForm.agencyCode,
          this.searchForm.mobile
        )
        .then((res) => {
          if (res.status === 200) {
            this.pagination.total = res.result.total;
            this.listData = res.result.list;
          }
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    getPersonalTenantList() {
      this.listLoading = true;
      api
        .getPersonalTenantList(
          this.pagination.current,
          this.pagination.pageSize,
          this.searchForm.tenantName,
          this.searchForm.agencyCode,
          this.searchForm.mobile
        )
        .then((res) => {
          if (res.status === 200) {
            this.pagination.total = res.result.total;
            this.listData = res.result.list;
          }
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
  }
})

